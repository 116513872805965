#payoff-calc-button{
  color:#000 !important;
  border:1px solid $green;
  border-radius: 10px;
  padding:15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight:800;
  white-space: nowrap;
  //width:fit-content !important;
  .chart{
    display: inline-block;
    margin-right:8px;
    $size:18px;
    width: $size;
    min-width:$size;
    height:$size;
    min-height: $size;
    background-image: url('/images/chart-green.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &:hover{
    background-color:$green;
    color:#FFF !important;
    .chart{
      background-image: url('/images/chart-white.svg');
    }
  }
  &.inverted{
    background-color:$green;
    color:#FFF !important;
    .chart{
      background-image: url('/images/chart-white.svg');
    }
    &:hover{
      background-color:unset;
      color:#000 !important;
      .chart{
        background-image: url('/images/chart-green.svg');
      }
    }
  }
}

.payoff-loan-row{
  display:flex;
  align-items: center;
  justify-content: space-between;
  height:150px;
  border:1px solid #E4E9F5;
  border-radius:17px;
  margin-bottom:55px;
  @include mobile-down{
    height:unset;
    flex-direction: column;
    align-items: flex-start;
    padding:30px;
    margin-bottom:12px;
  }
  &>div{
    height:100%;
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 30px;
    @include med-tablet-down{
      width:100%;
      padding:0;
    }
  }
  .contribution-columns{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    max-width:350px;
    margin:0 !important;
    @include mobile-down{
      max-width:100%;
    }
    label,.label{
      max-width:128px;
      font-weight:unset;
      @include mobile-down{
        max-width:100%;
        &:first-child{
          margin-right:12px;
        }
      }
      .input-holder{
        margin-top:12px;
        @include mobile-down{
          justify-content: flex-start;
          .input{
            width:100%;
          }
        }
        input{
          padding:0 5px;
          text-align: center;
        }
      }
    }
  }
  .title{
    border-right:1px solid #E4E9F5;
    font-size: 22px;
    font-weight: 700;
    white-space: nowrap;
    @include med-tablet-down{
      width:fit-content;
      padding:0 50px;
      min-width:250px;
    }
    @include mobile-down{
      padding:0;
      margin-bottom:26px;
      border:none;
    }
  }
}

.payoff-payments-holder{
  display:flex;
  align-items: center;
  justify-content: space-between;
  @include med-tablet-down{
    flex-direction: column;
  }
  &>div{
    width:calc(50% - 14px);
    border-radius:15px;
    @include med-tablet-down{
      width:100%;
      &:first-child{
        margin-bottom:45px;
      }
    }
    @include mobile-down{
      &:first-child{
        margin-bottom:12px;
      }
    }
    &.without-thrive{
      border:5px solid #6062BB;
    }
    &.with-thrive{
      border:5px solid $green;
    }
    .title {
      h2 {
        font-size: 20px;
        margin-bottom: 5px;
      }
      span{
        font-size:14px;
      }
    }
    .payment-row{
      padding:45px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom:1px solid #E4E9F5;
      label,.label{
        max-width:130px;
        .input-holder{
          margin:0;
        }
      }
      @include mobile-down{
        flex-direction: column;
        align-items: flex-start;
        .title{
          margin-bottom:24px;
          h2{
            margin-bottom:24px;
          }
        }
      }
    }
    .payoff-time{
      text-align: center;
      padding:20px 30px 30px;
      &>img{
        margin-bottom:10px;
      }
      @include med-tablet-down{
        padding:30px 30px 45px;
        &>img{
          margin-bottom:18px;
        }
      }
    }
  }

}
