.footer{
  background-color: #F5F7FC;
  font-family: lato, sans-serif;
  &>div{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:48px 34px;
  }
  .logo-holder{
    display: flex;
    align-items: center;
    font-size:14px;
    img{
      margin-right:18px;
      width:31px;
      max-width:31px;
      height:34px;
      max-height: 34px;
      object-fit: contain;
    }
  }
  span,a{
    color:#F67281;
  }
  a{
    font-weight:600;
    border-bottom:2px solid #F67281
  }
}

.signup-footer{
  background-color: rgb(52,54,154);
  position: fixed;
  height:80px;
  bottom:0;
  left:0;
  z-index: 10;
  &>div{
    height:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 27px;
    @include mobile-down{
      justify-content: flex-end;
      padding:0 10px;
    }
  }
  .mdi-help{
    display: inline-block;
    position: relative;
    width:16px;
    height:16px;
    max-width:16px;
    max-height:16px;
    font-size:10px;
    padding-bottom:2px;
    border-radius: 100%;
    border:2px solid #FFF;
  }
  button{
    color:#FFF;
    font-size: 18px;
    font-weight:700;
    padding:14px 35px;
    border-radius: 10px;
    position: relative;
    span{
      position: relative;
      z-index: 2;
    }
    @include mobile-down{
      padding:14px 18px;
      width:123px;
    }
    @include mobile-tiny-down{
      width:unset;
    }
    &:hover{
      &::before{
        content: "";
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: rgba(0,0,0,.2);
        border-radius:10px;
        z-index: 1;
      }
    }
  }
  .faq-button{
    border:1px solid rgba(21,201,186,.7);
    @include mobile-down{
      width:unset;
      height:80px;
      position: absolute;
      left:0;
      top:50%;
      transform: translateY(-50%);
      border:none;
      border-right:1px solid rgba(255,255,255,.07);
      border-radius:0;

    }
  }
  .back-button{
    background-color:rgba(21,201,186,.17);
  }
  .continue-button{
    background-color:rgb(21,201,186)
  }
  .progress-buttons{
    button:first-of-type{
      margin-right:10px;
    }
  }
}
.auth-footer{
  position: relative;
  height: 230px;
  padding-top:43px;
  padding-bottom:43px;
  color:#FFF;
  @include tablet-down{
    height:250px;
  }
  @include mobile-down{
    height:300px;
  }
  span{
    color:#FFF;
  }
  a{
    color:#FFF;
    text-decoration: none;
    border:none;
    display: inline-block;
    margin-bottom:20px;
    &:not(:last-child){
      margin-right:70px;
    }
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .row{
    align-items: flex-end;
  }
  .service-agreement{
    margin-left:30px;
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .logo{
    width:135px;
    max-width:135px;
    height:42px;
    max-height:42px;
    object-fit: contain;
  }
  .max-width-container{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .footer-contact-row{
    padding-top:27px;
    margin-top:27px;
    border-top:1px solid rgba(255,255,255,.5);
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include tablet-down{
      flex-direction: column;
      justify-content: center;
    }
  }
}
