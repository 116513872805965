.signup-header{
  .header-logo{
    margin-left:40px;
    @include mobile-down{
      margin-left:24px;
    }
  }
}
.signup-steps{
  display: flex;
  justify-content: center;
  align-items: center;
  height:60px;
  background-color: #6062BB;
  color:#FFF;
  .step{
    font-family: lato, sans-serif;
    font-size:18px;
    font-weight:600;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:0 20px;
    min-height:100%;
    height:100%;
    &.active{
      background-color: #323495;
      border-bottom: 5px solid #16BDAF;
    }
    &:not(:last-child){
      margin-right:30px;
    }
    &:nth-child(odd){
      span {
        padding-left: 1px;
      }
    }
    span{
      margin-top:2px;
      font-family: roboto, sans-serif;
      font-size:14px;
      font-weight:900;
      height:28px;
      width: 28px;
      max-height:28px;
      max-width: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border:1px solid #FFF;
      border-radius: 100%;
      margin-right:12px;
      padding-bottom:2px;
      text-align: center;
      &.active,&.complete{
        background-color: #FFF;
        color: #6062BB;
      }
      //&.complete{
      //  background-image: url("/images/check-white.svg");
      //  background-color:$green;
      //  background-repeat: no-repeat;
      //  background-position: center;
      //}
    }
  }
  .mobile-step{
    font-family: lato, sans-serif;
    font-size:18px;
    font-weight:600;
    strong{
      font-family: roboto, sans-serif;
      font-size:18px;
      font-weight:800;
      margin-right:5px;
    }
  }
  &.mobile,&.tablet{
    display: none;
  }
  @include tablet-down{
    &.desktop-up{
      display: none;
    }
    &.tablet{
      display:flex;
    }
  }
  @include mobile-down{
    &.tablet-up {
      display: none;
    }
    &.mobile{
      display:flex;
    }
  }
}
.slide-holder{
  padding-bottom:80px;
  display: flex;
  max-width: 100%;
  overflow: hidden;
}
.slide{
  position: relative;
  margin:70px auto 80px;
}
.welcome-holder{
  height:50px;
  border:1px solid $light-gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  padding:10px 30px 10px 10px;
  font-size:14px;
  margin-right:20px;
  @include mobile-down{
    border:none;
    margin:0;
    align-items: flex-end;
  }
  img{
    width:34px;
    max-width:34px;
    height:34px;
    max-height:34px;
    border:1px solid $light-gray;
    border-radius: 100%;
    margin-right:20px;
    @include mobile-down{
      display: none;
    }
  }
}
.welcome-slide{
  width:calc(100% - 66px);
  max-width:824px;
  text-align: center;
  background-color: #FFF;
  border-radius: 17px;
  border:1px solid $light-gray;
  box-shadow: 0px 0px 50px 0px rgba(0,0,0,.3);
  padding:58px 30px 90px;

  &>div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .thrive-logo{
      margin-bottom:10px;
      height:48px;
      width:44px;
      object-fit: contain;
    }
    &>button{
      height:70px;
    }
    p{
      font-size:30px;
      font-weight:700;
      line-height:38px;
      margin:50px 0 24px;
      max-width:670px;
      @include mobile-down{
        font-size:22px;
        line-height:29px;
      }
    }
    .details{
      font-size:24px;
      opacity: .8;
      margin-bottom:50px;
      max-width:670px;
      font-weight:500;
      a{
        color:#000;
        opacity: 1;
        &:hover{
          text-decoration: underline;
        }
      }
    }

  }
  .create-account{
    padding-bottom:0;
    label,.label{
      font-size: 12px;
      font-weight: 400;
    }
  }
  .welcome-logo{
    margin:50px auto;
    width:340px;
    height:112px;
    padding:20px 30px;

    box-shadow: 0px 0px 50px 0px rgba(0,0,0,.1);
    border:1px solid $light-gray;
    border-radius: 17px;
    @include mobile-down{
      width:214px;
      height:70px;
      max-width:100%;
      padding:18px 30px;
    }
    img{
      width:100%;
      height:100%;
      object-fit:contain;
    }
  }
  .buttons{
    flex-direction: row;
    top:90px;
    margin-top:-90px;
    position: relative;
    height:150px;
    border-top:1px solid #E4E9F5;
    button{
      font-size: 18px;
      width:134px;
      height:50px;
      border-radius:10px;
      color:#FFF;
      position: relative;
      &:first-child{
        margin-right:10px;
      }
      span{
        position: relative;
        z-index: 2;
      }
      &:hover{
        &::before{
          content: "";
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background-color: rgba(0,0,0,.2);
          border-radius:10px;
          z-index: 1;
        }
      }
    }
    .yes{
      background-color:$green;
    }
    .no{
      background-color:#F67281;
    }
  }
  &.company-owner{
    &>div:first-child{
      img{
        height:40px;
        width:auto;
      }
      span{
        font-size:18px;
        line-height: 21px;
        color:#333333;
      }
    }
    .welcome-logo{
      margin:10px auto 22px;
    }
  }
  .evoshare-no-button{
    position: absolute;
    bottom:-47px;
    background-color: $red;
    color:#fff;
    width:calc(100% + 2px);
    height:60px;
    left:-1px;
    border-radius: 0 0 17px 17px;
    &:hover{
     background-color: darken($red,10%);
    }
  }
}

.two-column-signup{
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top:26px;
  padding:0 27px;
  .left{
    min-width:391px;
    width: 391px;
    color:#000;
    padding:38px 34px;
    background-color: #FFF;
    border-radius:17px;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,.3);
    &>.title{
      font-size:28px;
      font-weight:900;
      margin-bottom:48px;
      &.with-border{
        padding-bottom:24px;
        margin-bottom:24px;
        border-bottom:1px solid rgba(255,255,255,.15);
      }
    }
    &>.contribution-columns{
      display: flex;
      padding:23px 0;
      margin-bottom: 24px;
      border-top:1px solid rgb(228,233,245);
      border-bottom:1px solid rgb(228,233,245);
      label,.label{
        &:not(:last-child){
          margin-right:30px;
        }
        .input-holder{
          border:none;
          &.fill{
            .input{
              width:100%;
            }
          }
          input,.input.with-border{
            border:1px solid rgb(228,233,245) !important;
            background:none;
            color:#000 !important;
          }
          .input.with-border:first-child{
            border-right:none !important;
          }
          &.dollar{
            span{
              border:1px solid rgb(228,233,245) !important;
              border-right:none;
              background-color: #FFF !important;
            }
          }
        }
      }
    }
    .white-card{
      &>img{
        width:100%;
        height:auto;
        margin-bottom:14px;
      }
      &>button{
        width:100%;
      }
    }
    p{
      font-size:15px;
      line-height: 21px;
      opacity: .9;
      margin-bottom:24px;
    }
    .page-copy{
      padding-bottom:24px;
      border-bottom:1px solid rgb(228,233,245);
      margin-bottom:40px;
    }
    &>button{
      width:100%;
    }
    &.accounts{
      p:last-child{
        margin-bottom:45px;
      }
    }
    .row{
      justify-content: space-between;
      padding-bottom:24px;
      margin-bottom:24px;
      .title,p{
        margin:0;
        padding:0;
      }
      &:not(:last-child){
        border-bottom:1px solid rgb(228,233,245);
      }
      .contribution-columns{
        padding:0;
        margin:0;
        border:none;
      }
    }
  }
  .right{
    width:calc(100% - 437px);
    display: flex;
    justify-content: stretch;

  }
  @include tablet-down{
    flex-direction: column;
    .left{
      padding:30px;
      margin-bottom:40px;
    }
    .left,.right{
      min-width:unset;
      width:100%;
    }
  }
  @include mobile-down{
    margin-top:54px;
    .right{
      width:100vw;
      .signup-calculator{
        box-shadow:none;
      }
    }
    .left{
      position: relative;
      width:100vw;
      border-radius: 0;
      left:-27px;
      margin-bottom:0;
      border-bottom:5px solid $green;
      box-shadow:none;
      .row{
        margin-bottom: 20px;
        .contribution-columns{
          width:100%;
        }
        &.title-row{
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          .title{
            width:100%;
            padding-bottom:50px;
            margin-bottom:20px;
            border-bottom:1px solid rgba(255,255,255,.15);
          }
          button{
            width:100%;
          }
        }
      }
    }
  }
}
.signup-calculator{
  padding:30px;
  width:100%;
  background-color:#FFF;
  border-radius: 17px;
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,.3);
  position: relative;
  height:fit-content;
  &.accounts{
    padding-bottom:80px;
  }
  .contribution-block{
    border-radius:15px;
    border:5px solid #6062BB;
  }
  .contribution-row{
    height:215px;
  }
  .totals{
    border:5px solid $green;
    margin-bottom:60px;
    .contribution-row{
      height:140px;
    }
  }
  .contribution-disclosure{
    font-size:22px;
    font-weight:700;
    margin:0;
    position: absolute;
    bottom:0;
    left:0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top:1px solid #E4E9F5;
  }
  @include mobile-down{
    width:100vw;
    left:-27px;
    border-radius: 0;
    &.accounts{
      padding-bottom:0;
    }
    .contribution-block{
      border:none;
    }
    .totals{
      border-radius: 0;
      border-top:5px solid $green !important;
    }
  }
}

.register-account-screen{
  text-align: center;
  padding-top:108px;
  padding-bottom:138px;
  &>img{
    margin-bottom: 10px;
  }
  &>h1{
    color:#333333;
    line-height:42px;
  }
  &>h2{
    font-size:22px;
    line-height: 25px;
    letter-spacing: 0;
    margin-bottom:47px;
  }

  &>button{
    margin-top:22px;
  }
  &>span:not(.standard-link){
    opacity: 0.7;
    margin-bottom:50px;
    display: block;
  }
  &>.standard-link{
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    bottom:43px;
  }
  .single-selector{
    width:100%;
    max-width:310px;
    margin:0 auto 50px;
  }
  &>.institution-button{
    margin-bottom:11px;
    width:100%;
    .single-selector{
      margin-bottom:0;
    }
  }
  .button-holder{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:0 30px;
    @include med-tablet-down{
      display: block;
      button{
        margin:0 auto;
        display:flex !important;
      }
      button:first-child{
        margin-bottom: 40px;
      }
    }
  }
  .form-container{
    margin-top:60px;
    border: 1px solid #E4E9F5;
    border-radius:17px;
    margin-bottom:35px;
    &.no-title{
      padding-top:35px;
    }
    &.no-border{
      border:none;
    }
    .form-inner{
      padding-left:77px;
      padding-right:77px;
      width:100%;
      @include mobile-down{
        padding-left:16px;
        padding-right:16px;
      }
    }
    .form-title{
      padding-top:35px;
      padding-bottom:35px;
      text-align: left;
      border-bottom: 1px solid #E4E9F5;
      margin-bottom:45px;
      font-weight:600;
      font-size:18px;
      line-height: 21px;
      color:#333333;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &>div {

        & > span {
          display: inline-block;

          & > span {
            position: relative;
            margin-left: 5px;
            top:-5px;
          }
        }
      }
      .remove-button{
        display: block;
        border:2px solid $red;
        border-radius: 10px;
        padding:8px;
        height:31px;
        font-size:14px;
        font-weight:500;
        line-height: 0px;
        &:hover{
          background-color:$red;
          color:#FFF;
        }
      }
    }
    .field-list{
      display: grid;
      grid-template-columns: calc(50% - 18px) calc(50% - 18px);
      grid-column-gap: 20px;
      grid-row-gap: 35px;
      margin-bottom:62px;
      &.single-column{
        grid-template-columns: 1fr;
      }
      @include mobile-down{
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

    }
  }
  label,.label {
    text-align: left;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;

    input, select {
      margin-top: 8px;
      padding: 0 22px;

      &::placeholder {
        color: #000;
        opacity: 0.25;
      }
    }

    select {
      color: rgba(#000, .25);
      -moz-appearance: none;
      -webkit-appearance: none;
      background-image: url("/images/down-chevron-blue.svg");
      background-repeat: no-repeat;
      background-position: right 20px bottom 50%;

      &:hover {
        cursor: pointer;
      }

      &.filled {
        color: #000;
      }
    }
  }
}

.manual-loan-message{
  span{
    font-size:18px;
    opacity: 1 !important;
    font-weight: 600;
    &:first-child{
      font-size:22px;
    }
  }
}

.contribution-columns.signup-top{
  display: flex;
  label,.label{
    &:first-child{
      margin-right:29px;
    }
    width:146px;
    max-width:146px;
  }
}


.evoshare-signup-slide{
  text-align: center;
  padding:50px 0 62px;
  .title{
    padding:0 16px 35px;
    border-bottom:1px solid rgb(228,233,245);
    &>h3{
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0px;
    }
    &>img{
      width:170px;
      max-width:100%;
      margin-bottom:20px;
      object-fit: contain;
    }
  }
  form{
    width:100%;
    max-width:332px;
    margin:0 auto;
    padding:92px 16px 0;
    label,.label {
      text-align: left;
      display: block;
      width: 100%;
      max-width: 100%;
      font-size: 14px;
      line-height: 14px;
      margin: 0 auto 20px;

      input {
        margin-top: 10px;
      }
    }
  }
  .button-holder{
    width:100%;
    max-width:332px;
    margin:0 auto;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 16px 150px;
    button{
      min-width:unset;
      width:calc(50% - 5px);
      padding:0;
      &.faded{
        background-color:rgba(#16BDAF,.07);
        span{
          color:#16BDAF;
        }
        &:hover{
          background-color:rgba(#16BDAF,.2) !important;
          &::before{
            display: none;
          }
        }
      }
    }
  }
  .contribution-disclosure{
    font-weight: 700;
    a{
      color:#16BDAF;
      text-decoration: underline;
    }
  }
}
.plan-details-slide{
  max-width: 1100px;
  @include tablet-down{
    padding-bottom:30px;
  }
  @include mobile-small-down{
    width:100%;
    border-radius: 0;
    padding:30px 16px 16px;
  }
}
.plan-details-holder{
  width:100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: stretch !important;
  margin-top:30px;
  @include tablet-down{
    flex-direction: column !important;
  }
  &>div{
    min-height:100%;
    width:calc(50% - 15px);
    border-radius: 17px;
    @include tablet-down{
      min-height: unset;
      width:100%;
      &:not(:last-child){
        margin-bottom:30px;
      }
    }
    &.plan-details{
      border:5px solid $purple;
      h3{
        color:$purple;
      }
      span{
        font-size:14px;
        font-weight: 700;
      }
      h3,span{
        display: block;
        text-align: left;
        padding:15px;
        border-bottom:1px solid $light-gray;
        &:last-child{
          border-bottom:none;
        }
      }

    }
    &.fees{
      border:5px solid $green;
      h3{
        color:$green;
        padding:15px;
        border-bottom:1px solid $light-gray;
      }
      h3,h4{
        text-align: left;
      }
      h4{
        margin-bottom:12px;
      }
      &>div{
        padding:15px;
        &:not(:last-child) {
          border-bottom: 1px solid $light-gray;
        }
        &>div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:not(:last-child){
            margin-bottom:8px;
          }
          @include mobile-small-down{
            display: block;
            margin-bottom:12px !important;
          }
          span{
            display: block;
            font-size:12px;
            font-weight:500;
            text-align: left;
            &:last-child{
              text-align: right;
            }
            @include mobile-small-down{
              margin-bottom:5px;
              &:last-child{
                &::before{
                  content: "- ";
                }
              }
              text-align: left !important;
            }
          }
        }
      }
    }
  }
}

.add-owner{
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start !important;
  padding-bottom:30px;
  margin-bottom:60px;
  border-bottom: 1px solid $blue-gray;
  button{
    min-height:40px !important;
    height:40px !important;
    padding:0 10px !important;
    min-width: unset !important;
  }
  &>span{
    margin-left:10px;
  }
}
