.terms-conditions-slide{
  .title{
    margin-bottom:18px;
    h2{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        margin-bottom:0;
        margin-right:10px;
      }
    }
  }
  &>a{
    color:$purple;
    display: block;
    margin-bottom: 20px;
    text-align: left;
    &:hover{
      cursor: pointer;
    }
  }
  .content-holder{
    border:1px solid #E4E9F5;
    border-radius:17px 17px 0 0;
    max-height:369px;
    padding:35px 16px 25px;
    margin-bottom:23px;

  }
  .confirmation{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
    @include mobile-down{
      flex-direction: column;
      align-items: flex-start;
    }

  }
}
.check-holder{
  height:50px;
  border: 1px solid #E4E9F5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding:10px 21px 10px 24px;
  font-size:14px;
  line-height: 18px;
  &.invalid{
    border:1px solid $red;
  }
  @include mobile-down{
    margin-bottom:10px;
  }
}
.form-slide{
  padding-bottom: 44px;
  @include mobile-down{
    padding-left:16px;
    padding-right:16px;
  }
  @include mobile-tiny-down{
    width:100%;
    border-radius: 0;
  }
  .title{
    margin-bottom:18px;
    h2{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        margin-bottom:0;
        margin-right:10px;
      }
    }
    p{

      text-align: center;
      font-size:16px;
      font-weight:400;
      color:#000;
      opacity: .5;
      line-height:19px;
      margin:18px 0 25px;
      max-width:620px;
    }
  }
  .form-container{
    border: 1px solid #E4E9F5;
    border-radius:17px;
    margin-bottom:35px;
    &.no-title{
      padding-top:35px;
    }
    &.no-border{
      border:none;
    }
    .form-inner{
      padding-left:77px;
      padding-right:77px;
      width:100%;
      @include mobile-down{
        padding-left:16px;
        padding-right:16px;
      }
    }
    .form-title{
      padding-top:35px;
      padding-bottom:35px;
      text-align: left;
      border-bottom: 1px solid #E4E9F5;
      margin-bottom:45px;
      font-weight:600;
      font-size:18px;
      line-height: 21px;
      color:#333333;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.wrap{
        flex-wrap: wrap;
        row-gap: 20px;
      }
      &>div {

        & > span {
          display: inline-block;

          & > span {
            position: relative;
            margin-left: 5px;
            top:-5px;
          }
        }
      }
      .remove-button{
        display: block;
        border:2px solid $red;
        border-radius: 10px;
        padding:8px;
        height:31px;
        font-size:14px;
        font-weight:500;
        line-height: 0px;
        &:hover{
          background-color:$red;
          color:#FFF;
        }
      }
    }
    .field-list{
      display: grid;
      grid-template-columns: calc(50% - 18px) calc(50% - 18px);
      grid-column-gap: 20px;
      grid-row-gap: 35px;
      margin-bottom:62px;
      &.single-column{
        grid-template-columns: 1fr;
      }
      @include mobile-down{
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

    }
  }
  label,.label{
    text-align: left;
    font-size:14px;
    line-height: 14px;
    font-weight: 400;
    input,select{
      margin-top:8px;
      padding:0 22px;
      &::placeholder{
        color:#000;
        opacity: 0.25;
      }
    }
    select{
      color:rgba(#000,.25);
      -moz-appearance: none;
      -webkit-appearance: none;
      background-image: url("/images/down-chevron-blue.svg");
      background-repeat: no-repeat;
      background-position: right 20px bottom 50% ;
      &:hover{
        cursor: pointer;
      }
      &.filled{
        color:#000;
      }
    }
  }
  .document-list-block{
    width:100%;
    padding:0 77px;
    @include mobile-down{
      padding: 0 16px;
    }
    label,.label{
      font-size:14px;
      font-weight:400;
      display: block;
      margin:30px auto;
      .file-input-container{
        background-color: #FFF;
      }
    }
  }
  .button-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    @include mobile-down{
      flex-direction: column-reverse;
    }
    button{

      &:first-child{
        margin-right:8px;
        @include mobile-down{
          margin-right:0;
        }
      }
      &:last-child{
        @include mobile-down {
          margin-bottom: 10px;
        }
      }

      &.faded{
        background-color:rgba(#6062BB,.07);
        span{
          color: #2E2F88;
          font-weight:400;
        }
        &:hover{
          background-color:rgba(#6062BB,.2);
          &::before{
            display: none;
          }
        }
      }
    }
  }
  .sub-title-box{
    h3 {
      font-size:16px;
      font-weight: 500;
      text-align: left;
      padding: 14px 16px;
      border: 1px solid #E4E9F5;
      border-radius: 5px;
      margin: 45px 0;
    }
  }
}

.document-options-selector{
  width:100%;
  max-width:616px;
  margin: 0 auto 10px !important;
  display: flex;
  //justify-content: space-between;
  &+a{
    color:$purple;
    margin-bottom:-14px;
    display: block;
    &:hover{
      text-decoration: underline;
    }
  }
  .check-holder{
    width:100%;
    max-width:250px;
    border:none;
    &:first-child{
      padding-left:0;
    }
    .checkbox{
      margin-left:8px;
      margin-right:0;
    }
  }
}
