.slide-down-appear {
  max-height:0%;
}
.slide-down-appear-active {
  max-height:100%;
  transition:max-height .5s;
}
.slide-down-enter {
  max-height:0%;
}
.slide-down-enter-active {
  max-height:100%;
  transition:max-height .5s;
}
.slide-down-exit {
  max-height:100%;
}
.slide-down-exit-active {
  max-height:0%;
  transition:max-height .5s;
}

.options-slide-down-appear {
  max-height:0;
}
.options-slide-down-appear-active {
  max-height:300px;
  transition:max-height .3s;
}
.options-slide-down-enter {
  max-height:0;
}
.options-slide-down-enter-active {
  max-height:300px;
  transition:max-height .3s;
}
.options-slide-down-exit {
  max-height:300px;
}
.options-slide-down-exit-active {
  max-height:0;
  transition:max-height .2s;
}

.fade-appear{
   opacity: 0;
 }
.fade-appear-active{
  opacity: 1;
  transition:opacity .3s;
}
.fade-enter{
  opacity: 0;
}
.fade-enter-active{
  opacity: 1;
  transition:opacity .3s;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity:0;
  transition:opacity .3s;
}

.fade-quick-appear{
  opacity: .5;
}
.fade-quick-appear-active{
  opacity: 1;
  transition:opacity .1s;
}
.fade-quick-enter{
  opacity: .5;
}
.fade-quick-enter-active{
  opacity: 1;
  transition:opacity .1s;
}
.fade-quick-exit {
  opacity: 1;
}
.fade-quick-exit-active {
  opacity:0;
  transition:opacity .1s;
}

.alert-appear {
  bottom:-100%;
}
.alert-appear-active {
  bottom:80px;
  transition:bottom 1s;
}
.alert-enter {
  bottom:-100%;
}
.alert-enter-active {
  bottom:80px;
  transition:bottom 1s;
}
.alert-exit {
  bottom:80px;
}
.alert-exit-active {
  bottom:-100%;
  transition:bottom 1s;
}
