$red: #F67281;
$orange:#FC825C;
$light-orange:#EBB04A;
$teal: #17D6C6;
$green:#15C9BA;
$blue: #5D5FB7;
$purple:rgb(74,76,146);
$light-gray:#EBEDF0;
$lightest-gray:#F5F7FC;
$blue-gray:#E4E9F5;
$max-width:1400px;
$tablet-max-width:1300px;
$tablet-medium-width:1024px;
$mobile-max-width:820px;
$mobile-small-width:500px;
$mobile-tiny-width:380px;
@mixin tablet-down{
  @media(max-width: $tablet-max-width) { @content }
}
@mixin tablet-up{
  @media(min-width: $tablet-max-width) { @content }
}
@mixin med-tablet-down{
  @media(max-width: $tablet-medium-width) { @content }
}
@mixin med-tablet-up{
  @media(min-width: $tablet-medium-width) { @content }
}
@mixin mobile-down{
  @media(max-width: $mobile-max-width) { @content }
}
@mixin mobile-small-down{
  @media(max-width: $mobile-small-width) { @content }
}
@mixin mobile-tiny-down{
  @media(max-width: $mobile-tiny-width) { @content }
}
*{
  padding:0;
  margin:0;
  outline: none;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: roboto, sans-serif;
  -webkit-backface-visibility:  hidden;
  -webkit-tap-highlight-color:  transparent;
  backface-visibility: hidden;
}
html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
button,.button{
  background: none;
  border:none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover{
    cursor: pointer;
  }
}
.clickable{
  &:hover{
    cursor: pointer !important;
  }
}
.standard-link{
  text-decoration: underline;
  font-weight: 700;
  &.green{
    color:$green;
  }
  &.purple{
    color: $purple;
  }
}
.full-width{
  width:100%;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.max-width-container{
  width:100%;
  max-width:$max-width;
  margin:0 auto;
}
.simple-rounded-button{
  box-sizing: inherit;
  padding:0 20px;
  min-height: 59px;
  min-width:160px;
  border-radius:10px;
  font-size:18px;
  font-weight:700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  @include mobile-down{
    &:not(.fixed) {
      display: flex;
    }
    &.huge{
      white-space: normal;
    }
    &.small{
      &:not(.fixed) {
        width: 100% !important;
      }
    }
  }
  .add{
    background: url("/images/plus-white.svg") no-repeat center;
    width:12px;
    min-width:12px;
    height:12px;
    min-height: 12px;
    display: inline-block;
    margin-right:11px;
  }
  span{
    position: relative;
    z-index: 2;
  }
  &.small{
    min-height: 50px;
    font-size:18px;
    padding:0 40px;
  }
  &.fit{
    min-width:unset;
  }
  &.list-button{
    padding:0;
    min-width:unset;
    width:100%;
  }
  &:hover{
    &:not(.hollow){
      &::before{
        content: "";
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: rgba(0,0,0,.2);
        border-radius:10px;
        z-index: 1;
        //@include med-tablet-down{
        //  display: none;
        //}
      }
    }
  }

  &.green{
    color:#FFF;
    &:not(.hollow) {
      background-color: $green;
    }
  }
  &.purple{
    color:#FFF;
    &:not(.hollow) {
      background-color: $purple;
    }
  }
  &.blue{
    color:#FFF;
    &:not(.hollow) {
      background-color: #1480D9;
    }
  }
  &.hollow{
    border: 1px solid #000;
    &.white {
      color:#FFF;
      border: 1px solid #FFF;
      &:hover{
        &::before{
          content: "";
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background-color: rgba(0,0,0,.2);
          border-radius:10px;
          z-index: 1;
          //@include med-tablet-down{
          //  display: none;
          //}
        }
      }
    }
    &.green{
      color:$green;
      background: none;
      border:1px solid $green;
      &:hover{
        color:#FFF;
        background-color: $green;
      }
    }
    &.blue{
      color:$purple;
      border:1px solid $purple;
      &:hover{
        color:#FFF;
        background-color: $purple;
      }
    }
  }
}

.inner-section{
  padding:0 90px;
  @include tablet-down{
    padding:0 16px;
  }
}
.status{
  position: relative;
  padding-left:14px;
  &.inactive{
    &::before {
      border: 1px solid #08D092;
    }
  }
  &.active{
    &::before {
      background-color: #08D092;
      border: 1px solid #08D092;
    }
  }
  &::before{
    content:"";
    position:absolute;
    display: block;
    left:0;
    top:calc(50% - 4px);
    $size:8px;
    height: 8px;
    min-height: 8px;
    width:8px;
    min-width:8px;
    border-radius:100%;
  }
}
.click-catcher{
  position: fixed;
  padding:0 !important;
  margin:0 !important;
  top:0;
  bottom:0;
  right:0;
  left:0;
  width:100vw;
  height:100vh;
  z-index: 10;
  background-color:rgba(0,0,0,.1);
  @include mobile-down{
    background-color:rgba(0,0,0,.2);
  }
}
.placeholder{
  content:"";
  background-color: #D2D7E7;
  min-height:30px;
  width:calc(100% - 20px);
  border-radius: 3px;
}
.placeholder-holder{
  position: relative;
  background-color: #EAECF4;
  border-radius: 10px;
  &::after{
    content: "";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    border-radius: 10px;
    opacity: .3;
    background-image:url("/images/103.gif");
    background-repeat: no-repeat;
    background-position: left 50% top 20px;
  }
  .table-row{
    &:nth-child(even){
      background:none;
    }
    &:nth-child(odd){
      background-color: #F5F7FC;
      border-radius: 5px;
    }
  }
}
.help{
  $size:16px;
  height: $size;
  width: $size;
  max-height:$size;
  max-width:$size;
  content:"";
  display: inline-block;
  background-image: url("/images/faq.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-right:8px;
  &.blue{
    background-image: url("/images/question-blue.svg");
  }
}
.tooltip-container{
  position: relative;
    .tooltip{
      display: block;
      position: absolute;
      max-width:100%;
      line-height: 19px;
      background: #fff;
      color: #000;
      border-radius: 5px;
      box-shadow: 3px 3px 24px 0px rgba(0,0,0,0.21);
      bottom: 100%;
      padding: 10px;
      text-align: center;
      left: 0;
      opacity: 0;
      transition: .5s;
      transform: translateY(50%);
    }
    &:hover{
      cursor: help;
      .tooltip{
        opacity: 1;
        transform: translateY(0);
      }
    }

}
.popup-message{
  position:fixed;
  bottom:80px;
  left:50%;
  transform: translateX(-50%);
  min-height:63px;
  width:100vw;
  max-width:600px;
  background-color:#2FC690;
  color:#FFF;
  display:flex;
  justify-content: center;
  align-items: center;
  opacity: .95;
  font-size:18px;
  z-index:1000;
  padding:16px;
  animation: alert-slide-up 6s ease;
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,.3);
  &.error{
    background-color: #EB1445;
  }
}
@keyframes alert-slide-up {
  0% {
    opacity: 0;
    transform: translate(-50%,80px);
  }
  15% {
    opacity: 1;
    transform: translate(-50%,0);
  }
  75% {
    opacity: 1;
    transform: translate(-50%,0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%,200px);
  }
}
.row{
  display:flex;
  justify-content: center;
  align-items: center;
}
.white-card{
  padding:14px;
  background-color:#FFF;
  border-radius: 10px;
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,.3);
  position: relative;
}
.react-datepicker-wrapper{
  display: block !important;
  position: relative;

  &::before{
    position: absolute;
    z-index: 2;
    color:$purple;
    left:20px;
    top:50%;
    transform: translateY(-50%);
    content:"\F0679";
    display: inline-block;
    font:normal normal normal 24px/1 "Material Design Icons";
    font-size:20px;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing:grayscale;
  }
  input {
    font-size: 16px;
    font-weight: 500;
    padding-left:48px;
  }
}
.react-datepicker__tab-loop{
  margin-top:0 !important;
}
.react-datepicker{
  border-radius: 0 !important;
}

.scrollBox{
  max-height:100%;
  width:100%;
  overflow: auto;
  padding:0 30px;
  @include mobile-down{
    padding:0 10px;
  }

  p,li{
    word-break: break-all;
    text-align: left;
    font-size:14px !important;
    font-weight:400 !important;
    color:#000 !important;
    opacity: .5 !important;
    line-height:22px !important;
    margin:0 0 25px !important;
    max-width:unset;
    li{
      opacity: 1 !important;
    }
    span{
      word-break: break-all;
    }
  }
}
@import "partials/header";
@import "partials/dashboard";
@import "partials/lists";
@import "partials/popups";
@import "partials/footer";
@import "partials/forms";
@import "partials/singlePages";
@import "partials/dropdowns";
@import "partials/reports";
@import "partials/transitions";
@import "partials/signup";
@import "partials/cards";
@import "partials/payoffCalculator";
@import "partials/contributions";
@import "partials/employeeAccounts";
@import "partials/ownerSignup";
@import "partials/evoshare";

