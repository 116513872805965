.contribution-block{
  margin-bottom:35px;
  border-radius:5px;
  border:1px solid $blue-gray;
  @include mobile-down{
    width:100vw;
    border-radius: 0;
    border-left:none;
    border-right:0;
    position: relative;
    left:-30px;
    margin-bottom:0;
    &.totals{
      border:none;
    }
  }
}
.contribution-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 0 0 30px;
  height:140px;
  @include med-tablet-down{
    display: block;
    height:unset !important;
    padding:35px 50px;
  }
  @include mobile-down{
    padding:50px 30px 45px;
  }
  .title{
    width:calc(100% / 3);
    display: flex;
    align-items: center;
    min-height:100%;
    border-right:1px solid $blue-gray;
    font-size:24px;
    font-weight:700;
    padding-right:30px;
    &.retirement-title{

      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &>div{
        margin-top:-8px;
        display:flex;
        align-items: stretch;
        &:first-child{
          &>img{
            margin-right:8px;
          }
        }
      }

      .retirement-info{
        display: flex;
        margin-top:8px;
        height:52px;
        max-width:175px;
        border:1px solid $blue-gray;
        border-radius: 5px;
        color:#000;
        img,span{
          min-height: 100%;
          padding:12px;
          width:50%;
          margin:0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size:11px;
          font-weight:500;
          object-fit: contain;
          object-position: left 0 bottom 50%;
        }
        span{
          border-left:1px solid $blue-gray;
        }
      }
    }
    &>img{
      height:40px;
      margin-right:8px;
    }
    span{
      margin-top:5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size:9px;
      font-weight:400;
      @include med-tablet-down{
        display: inline-flex;
        margin-left:15px;
      }
      img{
        margin-right:3px;
      }
    }
    @include med-tablet-down{
      width:100%;
      margin-bottom:28px;
      border:none;
    }
  }
  .contribution-columns{
    max-height:100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding:0 20px;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items:center;
    width:100%;
    &.col-2{
      grid-template-columns: 1fr 1fr;
    }
    @include med-tablet-down{
      justify-content: flex-start;
      grid-template-columns: repeat(auto-fit,140px);
      grid-gap:50px;
    }
    @include mobile-down{
      grid-gap:20px;
      justify-items: flex-start;
      padding:0;
    }
    @include mobile-tiny-down{
      grid-template-columns: 1fr;
    }
    label,.label{
      height:100%;
      width:100%;
      font-size:14px;
      font-weight: unset;
      &:nth-child(3){
        //width:100px;
      }
      @include mobile-tiny-down{
        width:100%;
        &:nth-child(3){
          width:100%;
        }
      }
    }
  }
  &:not(:last-child){
    border-bottom:1px solid $blue-gray;
  }

}

.input-holder{
  display: flex;
  border-radius: 5px;
  height:60px;
  justify-content: center;
  align-items: center;
  input,.read-only{
    font-size:18px;
    font-weight: 500;
    border:1px solid $blue-gray;
    border-radius: 5px;
    &.active-type{
      box-shadow: 0px 0px 5px 0px #6062BB;
      border:1px solid #9193EA;
      z-index: 2;
    }
  }
  .radio-button{
    margin-right:6px;
  }
  .input{
    box-sizing: content-box;
    display: flex;
    align-items: center;
    &.read-only{
      padding: 0 8px;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
    span{
      display: flex;
      align-items: center;
      justify-items: center;
      font-size:18px;
      font-weight:700;
    }
  }
  &.contribution-status{
    border:none;
    .read-only{
      color:#FFF;
      border:2px solid #15C9BA;
      background-color:#6062BB;
      box-shadow: 0px 0px 3px 0px rgba(21,201,186,.41);
      height:50px;
      border-radius: 24px;
      font-size:16px;
      font-weight:700;
    }
    .error{
      color:#FFF;
      background-color: $orange;
      border:2px solid #15C9BA;;
      box-shadow: 0px 0px 3px 0px rgba($orange,.41);
    }
  }
  &.dollar{
    input{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right:1px solid $blue-gray;
    }
    span {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height:50px;
      padding:0 9px;
      border:1px solid $blue-gray;
      border-left:none;
    }
    &.flipped{
      input{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left:1px solid $blue-gray;
      }
      span {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height:50px;
        padding:0 9px;
        border:1px solid $blue-gray;
        border-right:none;
      }
    }
  }
  &.split{
    .input{
      width:50%;
      input{
        padding: 0 8px;
        width: 100%;
        text-align: center;
        justify-content: center;
      }
      &.with-border{
        display: flex;
        justify-content: center;
        align-items: center;
        border:1px solid $blue-gray !important;
        height:50px;
        border-radius:5px;
        &:last-child{
          &:not(.single) {
            border-left: none !important;
          }
        }
      }
      &.with-border:first-child,&:first-child input,&.read-only:first-child{
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      &.with-border:last-child,&:last-child input,&.read-only:last-child{
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        &:not(.active-type) {
          &:not(.single) {
            border-left: none !important;
          }
        }
      }
    }
  }
}
.contribution-disclosure{
  position: relative;
  margin-bottom:50px;
  width:100%;
  border-radius:17px;
  text-align: center;
  padding:20px 10px;
  border:1px solid $blue-gray;
  background-color: $lightest-gray;

  @include mobile-down{
    &:not(.accounts) {
      //width: 100vw;
      border-radius: 0;
      padding: 20px 30px;
    }
    &.dashboard{
      left:-30px;
      margin-bottom: 28px;
    }
  }
}
.tenure-required{
  position: relative;
  font-size: 11px;
  .tooltip{
    display: block;
    position: absolute;
    max-width:100%;
    line-height: 19px;
    background: #fff;
    color: #000;
    border-radius: 5px;
    box-shadow: 3px 3px 24px 0px rgba(0,0,0,0.21);
    bottom: 100%;
    padding: 10px;
    text-align: center;
    left: 0;
    opacity: 0;
    transition: .5s;
    transform: translateY(50%);
  }
  &:hover{
    cursor: help;
    .tooltip{
      opacity: 1;
      transform: translateY(0);
    }
  }
}
