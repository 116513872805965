.filter-button{
  position: relative;
  font-size:15px;
  margin-left:10px;
  width:92px;
  height:50px;
  border-radius:10px;
  border:1px solid $light-gray;
  background-color:#FFF;
  padding: 0 33px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.full{
    width:100%;
    margin-left:0;
    justify-content: flex-start;
    padding: 0 33px 0 25px !important;
    .option-list{
      width:100%;
    }
  }
  &.squared{
    border-radius:5px !important;
  }
  &:hover{
    cursor: pointer;
  }
  &::after{
    position: absolute;
    display: inline-block;
    right: 14px;
    content:"";
    $size:8px;
    width:$size;
    min-width:$size;
    height:$size;
    min-height:$size;
    background-image:url('/images/down-chevron-blue.svg');
    background-repeat: no-repeat;
    background-position: left 50% bottom calc(50% + 2px);
    background-size: contain;
  }
  @include mobile-down{
    padding:16px 50px 16px 35px;
    width:100%;
    margin-left:0;
    text-align: center;
    border-radius:25px;
    &::after{
      position: relative;
      right:unset;
      margin-left:15px;
    }
  }
}
.option-list {
  display: flex;
  flex-direction: column;
  width: 207px;
  background-color: #fff;
  position: absolute;
  top:calc(100% + 5px);
  right:0;
  z-index: 15;
  border: 1px solid #E5E5E5;
  border-radius:7px;
  box-shadow: 0 4px 16px #00000016;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-height: 300px;
  overflow: scroll;
  @include mobile-down{
    max-height: unset;
    width:100%;
    text-align: left;
    position: relative;
    box-shadow: none;
    border:none;
    top:unset;
    span,a{
      margin:0 20px !important;
    }
  }
  span,a {
    display: block;
    padding: 25px 30px 25px 77px;
    line-height: 16px;
    background-image: url("/images/filter-unselected.svg");
    background-repeat: no-repeat;
    background-position: left 25px bottom 50%;
    font-weight: 400;
    font-size: 14px;
    color:#000;

    @include med-tablet-up {
      &:hover {
        background-color: #F6F8FC;
        font-weight: 700;
        color: rgb(51, 51, 51);
        cursor: pointer;
      }
    }
    &.selected{
      //background-color:#F6F8FC;
      font-weight: 700;
      color:rgb(51,51,51);
      cursor: pointer;
      background-image: url("/images/filter-selected.svg");
    }
  }
  &.single{
    width:100%;
    span,a{
      background:none;
      padding-left:0;
      &.selected{
        background:none;
      }
    }
  }
}
.single-selector{
  width:100%;
  padding-right:2px;
  height:50px;
  display:flex;
  padding-left:23px;
  justify-content: flex-start;
  align-items: center;
  border:1px solid #E4E9F5;
  border-radius: 5px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:not(.disabled):hover{
    cursor: pointer;
  }
  &.disabled{
    &:not(.display-only) {
      background-color: #F6F8FC;
    }
  }
  &:not(.disabled) {
    &::after {
      content: "";
      width: 43px;
      min-width: 43px;
      height: 100%;
      display: block;
      background-image: url("/images/down-chevron-blue.svg");
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 0;
      border-left: 1px solid #E4E9F5;
    }
  }
  &.simple{
    width:160px;
    display: inline-flex;
    &:not(:first-child) {
      margin-left: 16px;
    }
    &::after{
      border:none;
      background-image: url("/images/down-chevron-white.svg");
    }
    @include mobile-down{
      width:140px;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
  &>.status{
    padding-left:22px;
  }
  .with-logo{
    display: flex;
    align-items: center;
    max-width:calc(100% - 48px);
    img{
      margin-right:20px;
      width:20px;
      max-width: 20px;
      min-width: 20px;
      height:24px;
      max-height: 24px;
      min-height:24px;
      object-fit: contain;
    }
  }
  .option-list {
    span{
      padding-left: 20px;
    }
    .status {
      margin-left: 22px;
    }

  }
}
.multi-autocomplete{
  max-height: 0;
  transition:max-height 1s;
  &.active{
    max-height:1000px;
    transition: max-height 1s;
  }
  .option-list {
    max-height:300px;
    overflow: scroll;
    @include mobile-down{
      width:100%;
      text-align: left;
      position: absolute;
      border: 1px solid #E5E5E5;
      box-shadow: 0 4px 16px #00000016;
      top:calc(100% + 5px);
    }
    span{
      line-height:20px;
      padding:10px 0 10px 20px;
      &:first-child{
        color:#000;
        font-weight: 700;
      }
      &:hover{
        color:#000;
      }
    }
    &:hover{
      span{
        &:not(:hover) {
         &:first-child {
           color: rgba(51, 51, 51, .6);
           font-weight: 400;
         }
        }
      }
    }
  }
  .input-holder{
    position:relative;
    input{
      position: relative;
      z-index: 9;
    }
  }
  .selected-holder{
    &>div{
      margin-top:10px;
      display: inline-block;
      padding:3px 5px;
      border-radius: 6px;
      border:1px solid $light-gray;
      &:not(:last-child){
        margin-right:5px;
      }
      span{
        margin-left:5px;
        color:red;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}
