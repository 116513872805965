.evoshare-dashboard{
  .inner-card{
    border: 1px solid #E4E9F5;
    border-radius: 10px;
    padding:80px 94px;
    margin-bottom:28px;
    position: relative;
    @include tablet-down{
      padding:68px 48px;
    }
    @include mobile-down{
      padding: 48px;
    }
    @include mobile-small-down{
      width:calc(100% + 60px);
      left:-30px;
      border-radius: 0;
      border-left:none;
      border-right:none;
      padding: 48px 30px;
    }
    &>p{
      font: normal normal normal 18px/24px Roboto;
      letter-spacing: 0px;
      color: #333333;
    }
    &>h3{
      font: normal normal bold 23px/27px Roboto;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
      margin-bottom:15px;
    }
  }
  .buttons{
    margin:45px 0;
    border:none !important;
    button{
      margin-right:32px;
    }
    .green{
      background-color: darken($green,10);
    }
    a{
      color:#000;
      font: normal normal normal 16px/19px Roboto;
      opacity: .8;
      &:hover{
        text-decoration: underline;
      }
      @include mobile-down{
        display: block;
        margin-top:16px;
      }
    }
  }
  .evoshare-features{
    display:flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 57px;
    @include med-tablet-down{
      display: block;
    }
  }
  .evoshare-feature-card{
    padding:37px 40px 58px;
    box-shadow: 0px 11px 12px #4757761A;
    border-radius: 10px;
    width:calc((100% - 68px) / 3);
    min-height:100%;
    text-align: center;
    @include med-tablet-down{
      min-height:unset;
      width:100%;
      display: flex;
      align-items: center;
      text-align: left;
      padding:30px 20px;
      margin-bottom:12px;
    }
    img{
      $size:170px;
      width:$size;
      max-width: $size;
      height:$size;
      max-height: $size;
      margin-bottom:32px;
      @include med-tablet-down{
        $size:120px;
        width:$size;
        max-width: $size;
        height:$size;
        max-height: $size;
        margin-bottom:0;
        margin-right:35px;
      }
      @include mobile-down{
        $size:80px;
        width:$size;
        max-width: $size;
        height:$size;
        max-height: $size;
        margin-bottom:0;
        margin-right:35px;
      }
    }
    .description{
      text-align: center;
      @include med-tablet-down{
        text-align: left;
      }
      h3{
        font: normal normal bold 18px/21px Roboto;
        letter-spacing: -0.45px;
        color: #000000;
        margin-bottom:14px;
      }
      p{
        font: normal normal normal 15px/18px Roboto;
        letter-spacing: 0px;
        color: #677688;
      }
    }
  }
  .evoshare-clients{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile-down{
      flex-wrap: wrap;
    }
    .evoshare-client-holder{
      height:47px;
      max-width:127px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile-down{
        max-width:unset;
        width:30%;
        margin-bottom:20px;

        &.small{
          height:60px;
        }
      }
      @include mobile-small-down{
        width:50%;
        max-width:127px;
      }
      img{
        max-width:100%;
        max-height:100%;
        object-fit: contain;
      }
    }
  }
}

.evoshare-signup-slide{
  width:calc(100% - 66px);
  position: relative;
  left:33px;
  max-width:990px;
  text-align: left;
  background-color: #FFF;
  border-radius: 17px;
  border:1px solid $light-gray;
  box-shadow: 0px 0px 50px 0px rgba(0,0,0,.3);
  padding:58px 90px 90px;
  @include mobile-down{
    padding:58px 30px 90px;
  }
  .title{
    text-align: center;
    margin-bottom:60px;
    width:calc(100% + 180px);
    position: relative;
    left:-90px;
  }
  &>p{
    font: normal normal normal 18px/24px Roboto;
    letter-spacing: 0px;
    color: #333333;
  }
  &>h3{
    font: normal normal bold 23px/27px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-bottom:15px;
  }
  .buttons{
    text-align: left;
    width:unset;
    .green{
      background-color: darken($green,10);
    }
    .skip{
      //background-color: rgba(#FC825C,.1);
      background-color: rgba(80,82,158,1);
      span{
        //color: darken(#FC825C,10);
        color: #FFF;
      }
      &:hover{
        span{
          color:#FFF;
        }
      }
    }
    @include mobile-down{
      button{
        margin-bottom:20px;
      }
    }
  }
  @include mobile-down{
    left:unset;
    width:100%;
    border-radius: 0;
  }
.evoshare-clients{
  padding:0 60px;
  margin-bottom: 30px;
  @include med-tablet-down{
    padding:0 30px;
  }
}
  .contribution-disclosure{
    position: absolute;
    bottom:0;
    left:0;
    margin-bottom:0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
