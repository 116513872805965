.small-organization-card{
  width:100%;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  border:1px solid #E4E9F5;
  padding:12px 20px 12px 12px;
  border-radius: 5px;
  margin-bottom:15px;
  position: relative;
  &.active{
    background-color:#F6F8FC;
    border:1px solid #F6F8FC;
  }
  img,.logo-placeholder{
    $size:30px;
    width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;
    border-radius: 100%;
    object-fit: fill;
  }
  .logo-placeholder{
    opacity: .5;
    background-color:$purple;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom:1px;
    padding-left:1px;
    &::before{
      opacity: .5;
    }
  }
  .name-and-role{
    width:100%;
    text-align: left;
    padding:0 13px;
    max-width:calc(100% - 100px);
    @include mobile-down{
      max-width:calc(100% - 60px);
    }
    span{
      display: block;
    }
    .org-name{
      font-size:14px;
      font-weight:700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-type{
      font-size:15px;
      font-weight:400;
    }
  }
  .round-forward-button{
    $size:35px;
    width:$size;
    min-width: $size;
    height: $size;
    min-height:$size;
    border-radius:100%;
    border:1px solid $light-gray;
    background-image:url('/images/right-chevron-blue.svg');
    background-repeat: no-repeat;
    background-position: left calc(50% - 1px) bottom 50%;
    background-size: 12px;
  }
  .active-org-message{
    font-size:15px;
    @include mobile-down{
      max-width:40px;
    }
  }
  .round-forward-button,.active-org-message{
    position: absolute;
    right:20px;
  }
  &.viewing{
    background-color:#F6F8FC;
    border:1px solid #F6F8FC;
    .round-forward-button{
      display: none;
    }
  }
  &:hover{
    cursor: pointer;
    border:1px solid $purple;
    .round-forward-button{

      background-color:$purple;
      border:1px solid $purple;
      background-image:url('/images/right-chevron-white.svg');
    }
  }
}
