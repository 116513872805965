.report-holder{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include tablet-down{
    display: block;
  }
}
.report-column{
  width:calc((100% - 24px) / 3);
  height:100%;

  h5{
    display:flex;
    align-items: flex-start;
    height:62px;
    padding:0 20px;
    font-size:16px;
    color:$purple;
  }
  &>div{
    background-color:#F5F7FC;
    border-radius:5px;
    padding:17px 20px;
    span{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height:44px;
      padding:0 20px;
      font-size:14px;
      &:not(:last-child){
        border-bottom:1px solid #E4E9F5;
      }
      &:first-child{
        border-bottom:1px solid #E4E9F5;
      }
    }
  }
  @include tablet-down{
    width:100%;
    &>div {
      height: auto !important;
      span{
        padding:0;
        &:first-child{
          border-bottom:unset;
        }
        &:not(:last-child){
          border-bottom:1px solid #E4E9F5;
        }

      }
    }
    h5{
      margin-top:10px;
      padding:0;
      height:50px;
    }
    &:not(:first-child){
      h5{
        margin-top:60px;
      }
    }
  }
}
