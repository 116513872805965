.popup-form{
  display:grid;
  grid-template-columns: calc(50% - 18px) calc(50% - 18px);
  grid-column-gap: 20px;
  grid-row-gap: 35px;
  padding-bottom:100px;
  &.message{
    padding-top:38px;
    input,textarea{
      font-size:16px;
      font-weight: 500;
    }
    .message-buttons{
      button{
        &:not(:last-child){
          margin-right:20px;
          @include mobile-down{
            margin-right:0;
            margin-bottom:12px;
          }
        }
      }
    }
  }
  &.invite{
    padding:0;
  }
  @include mobile-down{
    grid-template-columns: 1fr;
  }
  .save-row{
    margin-top:20px;
    grid-column: 1/ span 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
    button{
      width:182px;
    }
    @include mobile-down{
      grid-column: 1;
    }
  }
  &>div{
    &.full-row{
      grid-column: 1/ span 2;
      @include mobile-down{
        grid-column: 1;
      }
    }
  }

}
label,.label{
  width:100%;
  font-size:15px;
  font-weight:400;

  &>div,&>input,&>textarea,&>select{
    background-color:rgba(255,255,255,0);
    margin-top:12px;
    font-size:16px;
    font-weight:500;
    &.invalid{
      border:2px solid $red;
    }
    @include mobile-down{
      margin-top:10px;
    }
  }
  &.full-row{
    grid-column: 1 / span 2;
    @include mobile-down{
      grid-column: 1;
    }
  }
  .read-only{
    height:50px;
    border-radius:5px;
    padding: 0 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F6F8FC;
    &.text-area{
      height:unset;
      padding:16px 28px;
    }
  }
}
.password-field{
  display: flex;
  align-items: center;
  .mdi{
    padding-top:6px;
    margin-left: 5px;
    font-size: 30px;
  }
}
input,textarea,select{
  display: block;
  width:100%;
  height:50px;
  border-radius:5px;
  border:1px solid #E4E9F5;
  padding: 0 28px;
  font-size: 16px;
  font-weight: 500;
  color:#000;
  &:focus{
    border:1px solid #9193EA;
  }
  @include mobile-tiny-down{
    padding:0 10px;
  }
}
textarea{
  height:240px;
  padding:20px 28px;
  resize: none;
}
input:disabled,textarea:disabled{
  background-color: #F6F8FC;
}

.small-form{
  width:100%;
  max-width:370px;
  margin:16px auto 0;
  padding-bottom:100px;
  label,.label{
    width:100%;
    text-align: left;
    display: block;
    margin-top:22px;
    input{
      margin-top:10px;
    }
  }
  .full-row{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:22px;
    &>span{
      color:$green;
      text-decoration: underline;
    }
    @include mobile-down{
      flex-direction: column-reverse;
      align-items: flex-start;
      button{
        margin-bottom:22px;
        width:100%;
      }
      &>span{
        width: 100%;
        text-align: center;
      }
    }
  }
  &.switch-accounts{
    &>span.clickable{
      position: absolute;
      bottom:30px;
      color:$green;
      text-decoration: underline;
    }
  }
}

.radio-button{
  position: relative;
  $size:20px;
  height:$size;
  width:$size;
  min-height:$size;
  min-width:$size;
  border:1px solid $blue-gray;
  background-color: $lightest-gray;
  border-radius: 100%;
  &.selected{
    &::after{
      content:"";
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      $size:10px;
      height:$size;
      width:$size;
      min-height:$size;
      min-width:$size;
      border-radius: 100%;
      border:2px solid #0FB9AB;
      background-color: $green;
    }
  }
}

.checkbox{
  height:30px;
  max-height: 30px;
  min-height: 30px;
  width:28px;
  max-width: 28px;
  min-width: 28px;
  border-radius: 5px;
  border: 2px solid $green;
  margin-right:30px;
  display: inline-block;
  &:hover{
    cursor: pointer;
  }
  &.invalid{
    border:1px solid $red;
  }
  &.checked{
    background-image: url("/images/check-white.svg");
    background-color:$green;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 14px;
  }
}

.file-descriptions-link{
  margin-left:6px;
}

.file-input-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height:50px;
  padding-left:16px;
  border: 1px solid #E4E9F5;
  border-radius: 5px;
  margin-top:10px;
  &.invalid{
    border:2px solid $red;
  }
  img:not(.uploading){
    width:12px;
    max-width:12px;
    height:20px;
    max-height:20px;
    margin-right:10px;
    @include mobile-down{
      display: none;
    }
  }
  .uploading{
    opacity: .3;
  }
  .file-info{
    display: flex;
    align-items: center;
    .file-placeholder{
      font-size:14px;
      line-height: 18px;
      opacity: .25;
    }
    .name{
      font-size:14px;
      line-height: 18px;
      margin-right:10px;
    }
    .size{
      font-size:12px;
      line-height:18px;
      opacity: .25;
      margin-right:8px;
    }
  }
  .file-button{
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    background-color:$green;
    height:100%;
    min-height: 100%;
    width:80px;
    min-width:80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#FFF;
    font-size:14px;
    line-height: 11px;
    font-weight: 500;
    &:hover{
      background-color:rgb(16,150,140);
    }
    &.remove{
      height:50px;
      background-color:#FFF;
      border:1px solid #F67281;
      color: #F67281;
      &:hover{
        background-color:rgba(#F67281,.05);
      }
    }
  }
}

.file-sub-text{
  font-size:11px !important;
  font-weight: unset !important;
  line-height: unset !important;
  max-width:unset !important;
  opacity: .7 !important;
  margin:0 !important;
}
