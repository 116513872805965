

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 54px 14px 54px 30px;
  @include mobile-down{
    padding:30px 20px;
  }
  &:nth-child(even) {
    background-color: #F5F7FC;
    border-radius: 5px;
  }

  span {
    font-size: 17px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000;
    display: block;
    &.capitalize{
      text-transform: capitalize;
    }
    img{
      margin-right:20px;
      max-width:12px;
      @include mobile-down{
        margin-right:10px;
      }
    }
  }
  &>span{
    &:not(:last-child):not(.file-download) {
      margin-right: 10px;
      text-align: left;
    }

    &:last-child {
      text-align: right;
      justify-self: flex-end;
    }
  }
  &.header{
    padding:28px 14px 28px 30px;
    span{
      font-weight:500;
    }
  }
  button{
    padding:0 20px;
    height:41px;
    min-height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight:700;
    font-size:15px;
    @include mobile-down{
      width:100%;
    }
  }
  a,button{
    &.employee{
      border:1px solid rgba(80,82,158,.2);
      &:hover{
        border:1px solid #50529E;
        background-color:#50529E;
        color:#FFF !important;
      }
    }
    &.payroll{
      border:1px solid rgba(23,209,193,.2);
      &:hover{
        border:1px solid rgb(23,209,193);
        background-color:rgb(23,209,193);
        span {
          color: #FFF !important;
        }
      }
    }
    &.elections{
       border:1px solid rgba(252,130,92,.2);
       &:hover{
         border:1px solid rgb(252,130,92);
         background-color:rgb(252,130,92);
         span {
           color: #FFF !important;
         }
       }
     }
    &.invoice{
      border:1px solid rgba(20,128,217,.58);
      &:hover{
        border:1px solid rgb(20,128,217);
        background-color:rgb(20,128,217);
        span {
          color: #FFF !important;
        }
      }
    }
  }
}
.employee-row{
  display:grid;
  grid-template-columns: 2fr 4fr 2fr 2fr 2fr 2fr 2fr 140px;
  &.with-selector{
    grid-template-columns: 1fr 2fr 4fr 2fr 2fr 2fr 2fr 2fr 140px;
  }
  &.tablet{
    grid-template-columns: repeat(auto-fit,minmax(140px,1fr));
    grid-gap:35px;
    &.bulk-actions{
      padding-top:0;
      padding-bottom:50px;
      display:flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap:10px;
      @include mobile-down{
        flex-direction: column;
        align-items: flex-start;
      }
      .select-holder {
        position: relative;
        left:unset;
        top:unset;
        width:unset;
        .label{
          display: none;
        }
        .checkbox{
          margin-right:0;
        }
        @include mobile-down{
          width:100%;
          display: flex;
          align-items: center;
          .checkbox{
            display: inline-block;
          }
          .label{
            display: inline-block;
            margin-left:10px;
          }
        }
      }
      button{
        max-width:180px;
        border:2px solid $green;
        @include mobile-down{
          max-width:unset;
        }
        span {
          font-size: 14px;
        }
        &:hover{
          border:2px solid rgba(0,0,0,0);
          span{
            color:#FFF;
          }
        }
      }
    }
    &.with-selector {
      position: relative;
      padding-left:50px;
      .select-holder {
        position: absolute;
        left:10px;
        top:68px;
      }
      &:first-child{
        padding-left: 30px;
        .select-holder {
          position: relative;
          left: unset;
          top: unset;
        }
        @include tablet-down {
          padding-left: 10px;
          .select-holder {
            position: relative;
            left: 0;
            top: 0;
          }
        }
        @include mobile-down{
          padding-left:30px;
        }
      }
    }
    &.large-columns{
      grid-template-columns: repeat(auto-fit,200px);
      div{
        position: relative;
      }
      @include mobile-down {
        grid-template-columns: 1fr;
        button {
          width: 100%;

        }
      }
    }
    &:nth-child(2n-1) {
      background-color: unset;
      border-radius:unset;
    }
    &:nth-child(2n) {
      background-color: #F5F7FC;
      border-radius: 5px;
    }
    &>div{
      span{
        &.title{
          margin-bottom:8px;
          font-weight:500;
        }
        button{
          width:fit-content;
          min-height:unset;
          height:unset;
          padding:9px 20px;
          color:$purple;
          @include mobile-down{
            padding:8px 16px;
          }
        }
      }
      &:not(:last-child) {
        width: 100%;
        span {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
    @include mobile-small-down{
      grid-row-gap: 20px;
      grid-column-gap: 10px;
    }
    @include mobile-tiny-down{
      display: flex;
      flex-direction: column;
    }
  }
  &.mobile{
    max-height:0;
    transition: max-height .3s, padding .3s;
    overflow: hidden;
    padding:0 35px;
    &>span{
      grid-column: 1 / 3;
      width:100%;
      button {
        width: 100%;
      }
    }
    &.open{
      max-height:1000px;
      padding: 40px 35px 42px;
      transition: max-height .8s, padding .8s;
    }
  }
}

.file-row{
  &.flagged{
    border:1px solid #F67281;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px rgba(246,114,129,.4);
  }
  &>span{
    width:134px;
    margin-left:10px;
    &:first-child{
      margin-left:0;
      width:calc(100% - 260px);
    }
    &:last-child{
      display:flex;
      justify-content: center;
    }
    a{
      $size:41px;
      width:$size;
      min-width:$size;
      height:$size;
      min-height:$size;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      span{
        position: relative;
        left:1px;
        bottom:1px;
      }
    }
  }
  &.tablet {
    display: grid;
    grid-template-columns: repeat(auto-fit, 120px);
    grid-gap: 30px;
    @include mobile-down{
      grid-template-columns: 1fr;
    }
    &.activity{
      grid-template-columns: calc(100% - 85px) 75px;
      padding:45px 20px;
      grid-gap:10px;
    }
    &:nth-child(2n) {
      background-color: unset;
      border-radius: unset;
    }

    &:nth-child(2n-1) {
      background-color: #F5F7FC;
      border-radius: 5px;
    }

    & > div {
      span {
        &.title {
          font-weight: 500;
        }

        a{
          $size:41px;
          width:$size;
          min-width:$size;
          height:$size;
          min-height:$size;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          span{
            position: relative;
            left:1px;
            bottom:1px;
          }
        }
      }

      &:not(:last-child):not(.file-download) {
        width: 100%;

        span {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
      }
      &.file-download{
        .title{
          margin-bottom:8px;
        }
      }
    }
  }
}
.payroll-list{
  .file-row{
    &>span{
      width:100%;
      padding:0 !important;
      margin:0 !important;
    }
    display: grid;
    grid-template-columns: 5fr 2fr 2fr 1fr 1fr;
    grid-gap:10px;
    &.col-6{
      grid-template-columns: 4fr 2fr 2fr 1.5fr 1.5fr 1.5fr;
    }
    @include mobile-down{
      grid-template-columns: repeat(auto-fit,150px);
      &.col-6{
        grid-template-columns: repeat(auto-fit,150px);
      }
    }
  }
}
.invoices-list{
  .file-row {
    & > span {
      width: 100%;
      padding: 0 !important;
      margin: 0 !important;
    }

    display: grid;
    grid-template-columns: 5fr 2fr 1.5fr 1.5fr 1fr;
    grid-gap: 10px;

    @include mobile-down{
      grid-template-columns: repeat(auto-fit,150px);
      &.col-6{
        grid-template-columns: repeat(auto-fit,150px);
      }
    }
  }
}
.message-list{
  .file-row{
    &>span{
      &:not(:first-child) {
        width: 140px;
      }
    }
    &.tablet{
      padding:30px 20px;
      @include mobile-down{
        grid-template-columns: 1fr 1fr;
        &>div:first-child,&>div:last-child{
          grid-column: 1 / span 2;
        }
      }
    }
  }
  i{
    font-weight:700;
  }
}
.employee-activity-list{
  .table-row{
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
    grid-column-gap:10px;
    grid-row-gap: 30px;
    .title{
      font-size:17px;
      font-weight:700;
      margin-bottom:8px;
    }
    @include tablet-down{
      grid-template-columns: repeat(auto-fit,162px);
    }
    @include mobile-down{
      grid-template-columns: 1fr 1fr;
      &>div:last-child{
        grid-column: 1 / span 2;
      }
    }
    @include mobile-tiny-down{
      grid-template-columns: 1fr;
      &>div:last-child{
        grid-column: 1 ;
      }
    }
  }
}
.user-list{
  .file-row{
    &>span{
      width:100%;
      &:nth-child(1){
        max-width:300px;
      }
      &:nth-child(3){
        max-width:200px;
      }
      &:nth-child(4){
        max-width:140px;
      }
      @include tablet-down{
        &:nth-child(1){
          max-width:200px;
        }
        &:nth-child(3){
          max-width:100px;
        }
        &:nth-child(4){
          max-width:120px;
        }
      }
    }
  }
}
.dashboard-list{
  @include mobile-down{
    &.drawer-list {
      position: relative;
      width: 100vw;
      left: -30px;
    }
  }
}
.mobile-employee-drawer{
  &.with-selector{
    position: relative;
    .mobile-employee-header{
      padding-left:68px;
    }
    .select-holder{
      position: absolute;
      left:30px;
      top:40px;
    }
  }
  &:nth-child(odd){
    background-color:#F5F7FC;
  }
}
.mobile-employee-header{
  padding:40px 35px 42px;
  color:#333333;
  position: relative;
  &::after{
    display: block;
    content:"";
    position: absolute;
    top:calc(50% - 5px);
    right:35px;
    $size:10px;
    width:$size;
    min-width:$size;
    height:$size;
    min-height:$size;
    background-image:url('/images/down-chevron-blue.svg');
    background-repeat: no-repeat;
    background-position: left 50% bottom calc(50% + 2px);
    background-size: contain;
    transition: transform .3s;
  }
  &.open{
    border-bottom:1px solid #EBEDF0;
    &::after{
      transform: rotateZ(180deg);
      transition: transform .3s;
    }
  }
  span{
    display: block;
  }
  .title{
    font-size:15px;
    font-weight:700;
    margin-bottom:14px;
  }
}

.no-results{
  width:100%;
  height:400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color:rgb(245,247,252);
  border-radius:10px;
  img{
    opacity: .3;
    margin-bottom:10px;
  }
  div{
    opacity: .5;
    text-align: center;
    margin-bottom:20px;
    font-size:24px;
  }
  button{
    width: 160px;
  }
}
